// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import $ from 'jquery';

import '../stylesheets/base.scss'

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require('@client-side-validations/client-side-validations')

import 'bootstrap'

import 'controllers'

let blob

document.addEventListener('turbolinks:before-visit', () => {
  blob = false;
})