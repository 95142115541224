import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ['form']

  connect() {
    var firstErrorElement = this.element.querySelector('.field-with-errors')

    if (firstErrorElement) {
      this.data.set('step', firstErrorElement.closest('.step').dataset.step)
    }

    this.update()
  }

  prev(event) {
    event.preventDefault()

    this.data.set('step', parseInt(this.data.get('step')) - 1)
    this.update()
  }

  next(event) {
    event.preventDefault()

    this.data.set('step', parseInt(this.data.get('step')) + 1)
    this.update()
  }

  submit(event) {
    event.preventDefault()

    this.formTarget.submit()
  }

  update() {
    this.element.querySelectorAll('.step').forEach((element, index) => {
      if (index == this.data.get('step') - 1)
        element.classList.remove('d-none')
      else
        element.classList.add('d-none')
    })
  }
}