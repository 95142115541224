import { Controller } from 'stimulus';
export default class extends Controller {
  connect() {

    var elements = document.querySelectorAll('*[data-toggle="modal"]')

    elements.forEach((element) => {
      element.addEventListener('click', () => {

        var modal = document.querySelector(element.dataset.target)
        var hash = modal.id;
        var href = element.href

        window.location.hash = hash;
        window.onhashchange = () => {
          if (!location.hash) {
            $(modal).modal('hide')
          }
        }

        href += (href.indexOf('?') > 0 ? '&' : '?')

        fetch(href + 'modal=1')
          .then(response => {
            return response.text()
          }).then(html => {
            modal.querySelector('.modal-content').innerHTML = html
          })
      })
    })

    // $(document).on('hidden.bs.modal', '#modal', function (event) {
    //   alert('..')
    //   var hash = this.id;
    //   history.pushState('', document.title, window.location.pathname);

    //   $('.modal-content').html('');
    // })
  }
}
