import Swiper from 'swiper';
import { Controller } from 'stimulus';
export default class extends Controller {
  connect() {
    new Swiper(this.element, {
      direction: 'horizontal',
      slidesPerView: 1,
      mousewheel: true,
      speed: 500,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        768: {
          spaceBetween: 30,
          direction: 'vertical'
        }
      }
    })
  }
}