import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ['zipcode', 'number', 'city', 'street', 'result']

  connect() {
    if (this.streetTarget.value != '') {
      this.result()
    }
  }

  result() {
    this.resultTarget.classList.remove('d-none')
  }

  search() {
    if (this.data.has('timeout')) {
      clearTimeout(this.data.get('timeout'))
    }

    this.data.set('timeout', setTimeout(() => {
      if (this.zipcodeTarget.value.length < 6 || this.numberTarget.value == '')
        return

      fetch('/orders/pro6pp?zipcode=' + this.zipcodeTarget.value + '&number=' + this.numberTarget.value)
        .then(response => response.json())
        .then((json) => {
          this.streetTarget.value = json['street']
          this.cityTarget.value = json['city']
        })

      this.result()
    }, 500))
  }
}