import Confetti from '../scripts/confetti.js'
import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = [ "output" ]

  connect() {
    if(this.cover()){
      Confetti.start()
  
      return
    }
  }

  disconnect() {
    if(this.cover()) Confetti.stop()
  }

  pop(event) {
    var c = document.createDocumentFragment();
    for (var i=0; i<50; i++) {
      var styles = 'transform: translate3d(' + (this.random(500) - 250) + 'px, ' + (this.random(200) - 150) + 'px, 0) rotate(' + this.random(360) + 'deg);\
                    background: hsla('+this.random(360)+',100%,50%,1);\
                    animation: bang 700ms ease-out forwards;\
                    opacity: 0';
        
      var e = document.createElement("i");
      e.style.cssText = styles.toString();
      c.appendChild(e);

      e.addEventListener('animationend', (event) => {
        event.target.remove()
      });
    }
    this.outputTarget.setAttribute('style', `top:${event.pageY}px;left:${event.pageX}px;`)
    this.outputTarget.append(c);
  }

  cover() {
    return (this.element.tagName != 'BODY');
  }

  random(max) {
    return Math.random() * (max - 0) + 0;
  }
}