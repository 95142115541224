import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["element", "input"];

  connect() {
    this.update();
  }

  update() {
    this.elementTargets.forEach((element) => {
      if (
        element.dataset.value.indexOf("|" + this.inputTarget.value + "|") > -1
      ) {
        element.style.removeProperty("display");
      } else {
        element.style.display = "none";
      }
    });
  }
}
