import { Controller } from 'stimulus';
export default class extends Controller {
  connect() {
  }

  click(event) {
    event.preventDefault()

    var answer = prompt(this.data.get('message'))

    if (answer) {
      this.element.href += ((this.element.href.indexOf('?') > 0 ? '&' : '?') + 'prompt=' + encodeURI(answer))
    }

    window.location.href = this.element.href


  }
}