import Confetti from '../scripts/confetti.js'
import { Controller } from 'stimulus';
export default class extends Controller {
  connect() {
  }

  submit() {
    this.element.submit()
  }

}