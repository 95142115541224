import copy from "copy-to-clipboard";

import { Controller } from "stimulus";

export default class extends Controller {
  copy(e) {
    e.preventDefault();

    copy(document.getElementById(e.target.dataset.copy).innerText);
  }

  preventEnter(e) {
    if (e.which == 13) e.preventDefault();
  }
}
