import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["number", "input"];

  connect() {
    this.change();
  }

  change() {
    this.numberTarget.innerHTML = this.inputTarget.value.length;
  }
}
