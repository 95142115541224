import { Controller } from 'stimulus';
export default class extends Controller {
  connect() {
    if (window.blob) {
      if (this.element.classList.contains('blob-appear')) {
        this.element.className = ' blob-active ' + window.blob.class
      } else {
        setTimeout(() => {
          this.element.className = 'blob-active'
          this.element.classList.add(window.blob.class)
        }, 1)
      }
    }
  }
}