import { Controller } from 'stimulus';
export default class extends Controller {

  connect() {
    document.addEventListener('turbolinks:request-start', this.out)
    document.addEventListener('turbolinks:load', this.in)
    document.addEventListener('turbolinks:before-cache', this.remove)
  }

  out() {
    document.querySelector('.container').classList.add(...['animate__animated', 'animate__fadeOut'])
  }

  in() {
    document.querySelector('.container').classList.add(...['animate__animated', 'animate__fadeIn'])
  }

  remove() {
    document.querySelector('.container').classList.remove(...['animate__animated', 'animate__fadeIn']);
  }
}